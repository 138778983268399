@import "variables";
@import "framework/framework";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel";

html {
	background: $color-blue;
	color: $color-white;
	font-family: $primary-font;
	font-size: $html-font-size;
	font-weight: 400;
	line-height: 1;
	overflow-x: hidden;
	text-transform: uppercase;
}

body {
	background: $color-black;
	color: $color-white;
	background-image: url('../img/slimerre2.jpg');
	background-size: cover;
	background-position: bottom center;

	
}

a {
	color: inherit;
	img{
		transition: .3s opacity;
	}
	@include hover-focus {
		color: $color-pink;
		img{
			opacity: .7;
		}
	}
}

.container {
	max-width: 900px;
	@media screen and (max-width: 991px){
		padding: 0 8px;
	}
}

.btn {
	display: inline-block;
	font-size: 12px;
	letter-spacing: letter-spacing(50);
	line-height: 1;
	text-align: center;
	text-indent: text-indent(50);
	vertical-align: middle;

	&.btn-outline {
		// background: $color-pink;
		color: $color-pink;
		border: 2px solid $color-pink;
		padding: 1em 2em;

		@include hover-focus {
			background: $color-pink;
			color: $color-black;
		}
	}

	&.btn-solid {
		background: $color-pink;
		color: $color-white;
		font-size: 15px;
		padding: .7em 2em;
		text-decoration: none;

		@include hover-focus {
			background: $color-white;
			color: $color-pink;
		}
	}
}



#header{
	background-image: url('../img/slimerre2.jpg');
	background-size: cover;
	background-position: 50% 55%;
	padding: 0;
	text-align: center;
	position: relative;
	&::before{
		content:'';
		width: 100%;
		height: 200px;
		position: absolute;
		top: 0;
		left: 0;
		background-image: linear-gradient(to bottom, #000C, #0000);
		
	}
	
	&::after{
		content:'';
		width: 100%;
		height: 200px;
		position: absolute;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(to top, #000C, #0000);
	}

	.container {
		position: relative;
		z-index: 2;
		min-height: 90vh;
		max-width: 1300px;
		display: flex;
		flex-direction: column;
		justify-content: flex;
	}
	
	img{
		margin: 0 auto;
	}
	h1{
		flex: 1;
		padding-bottom: 300px;
		img{
			width: 150px; 
		}
	}
	.titletext{
		font-size: 100px;
		font-weight: 900;
		letter-spacing: .2em;
		margin-right: -.2em;
		text-transform: uppercase;
		@media screen and (max-width: 1300px){
			font-size: 80px
		}
		@media screen and (max-width: 991px){
			font-size: 60px
		}
		@media screen and (max-width: 600px){
			font-size: 36px
		}
	}
	
	span{
		margin: 15px 0 20px;
		display: block;
		font-size: 22px;
		letter-spacing: .05em;
		font-weight: bold;
		font-family: $primary-font;
	}
	.btn-outline{
		font-size: 14px;
	}
	.btn-stripe{
		position: relative;
		z-index: 3;
		font-family: $header-font;
		font-weight: bold;
		width: 100%;
		line-height: 1.3;
		font-size: 20px;
		transform: translateY(50%);
		padding: 8px 0 10px;
		background-color: $color-blue;
		background-size: 100% 100%;
		text-decoration: underline;

		&:hover, &:focus{
			color: #000;
			background-color: $color-white;
		}
		
		@media screen and (max-width: 700px){ 
			background-size: auto 100%;
		}
	}
}

main {
	padding: 0 10px;
	section {
		padding: 10px 0 30px;
		width: 100%;

		max-width: 1000px;
		margin: 50px auto;

		background-color: $color-blue-t;
	}

	.arrow{
		width: 24px !important;
		@media screen and (max-width: 991px){
			width: 18px !important;
		}
	}

	.section-title {
		font-size: 32px;
		font-weight: 700;
		font-family: $header-font;
		letter-spacing: letter-spacing(50);
		line-height: 1;
		margin: 0 0 16px 0;
		text-align: center;
		text-indent: text-indent(50);
	}

	.more {
		display: none;

		margin-top: 30px;

		text-align: center;

		> a {
			font-weight: 700;
		}
		.btn{
			font-family: $secondary-font;
			border-width: 2px;
			padding: 10px 20px 15px;
			font-size: 17px;
		}
	}

	#music {
		.container {
			max-width: 1100px;
		}

		.owl-carousel {
			padding: 0 50px;
			@media screen and (max-width: 991px){
				padding: 0 20px;
			}

			article {
				text-align: center;

				> span {
					font-family: $secondary-font;
					display: block;
					font-size: 17px;
					font-weight: 700;
					letter-spacing: letter-spacing(50);
					line-height: 1;
					margin: 15px 0 15px 0;
					text-indent: text-indent(50);
				}
				
			}

			.btn{
				font-size: 12px;
				padding: 3px 8px 4px;
			}

			.owl-nav {
				> button {
					font-size: 35px;
					line-height: 1;
					position: absolute;
					top: 40%;
					transform: translateY(-50%);

					&.owl-prev {
						left: 0;
					}

					&.owl-next {
						right: 0;
					}
				}
			}
		}
	}

	#videos {

		.container {
			max-width: 1000px;
		}

		.owl-carousel {
			padding: 0 50px;
			@media screen and (max-width: 991px){
				padding: 0 20px;
			}

			.owl-item {
				> article {
					> a {
						display: block;
						opacity: 1;
						position: relative;

						@include hover-focus {
							opacity: 0.8;
							img{
								opacity: 1;
							}
						}

						&:before {
							background: linear-gradient(to top, #000C, #0000) no-repeat center top;
							background-size: 100% 100%;
							bottom: 0;
							content: '';
							left: 0;
							position: absolute;
							right: 0;
							top: 0;
						}

						> span {
							font-size: 17px;
							font-weight: 700;
							left: 0;
							letter-spacing: letter-spacing(50);
							line-height: 1;
							padding: 0 10px;
							position: absolute;
							right: 0;
							text-align: center;
							text-indent: text-indent(50);
							top: 50%;
							transform: translateY(-50%);
						}
					}
				}
			}

			.owl-nav {
				> button {
					font-size: 35px;
					line-height: 1;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					&.owl-prev {
						left: 0;
					}

					&.owl-next {
						right: 0;
					}
				}
			}
		}
	}

	#tour {
		#events {
			> div {
				align-items: center;
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				font-size: 17px;
				letter-spacing: letter-spacing(50);
				line-height: 1;
				text-indent: text-indent(50);
				@media screen and (max-width: 991px){
					flex-direction: column;
					.event-date, .event-location{
						margin-bottom: 10px;
					}
				}

				+ div {
					margin-top: 20px;
				}

				&:nth-child(n+ 6) {
					display: none;
				}

				.event-date {
					flex: 0 0 5%;
					font-family: $secondary-font;
				}

				.event-location {
					flex: 0 0 30%;
					font-family: $secondary-font;
					@media screen and (max-width: 991px){
						text-align: center;
					}
				}

				.event-venue {
					flex: 0 0 40%;
					@media screen and (max-width: 991px){
						text-align: center;
					}
				}

				.event-links {
					text-align: right;
					@media screen and (max-width: 991px){
						margin-top: 10px;
						text-align: center;
					}
					flex: 0 0 25%;
					

					.btn{
						padding: 3px 8px 4px;
					}
				}

				.event-date, .event-location {
					font-weight: 700;
				}

				.event-location, .event-venue {
					padding: 0 25px;
				}
			}
		}
	}

	#merch{
		.container{
			max-width: 1000px;
		}
		.owl-carousel {
			padding: 0 50px;
			@media screen and (max-width: 991px){
				padding: 0 20px;
			}

			.item {
				text-align: center;

				> span {
					font-family: $secondary-font;
					display: block;
					font-size: 17px;
					font-weight: 700;
					letter-spacing: letter-spacing(50);
					line-height: 1;
					margin: 10px 0 15px 0;
					text-indent: text-indent(50);
				}

				.btn{
					padding: 4px 8px;
				}
			}

			.owl-nav {
				> button {
					font-size: 35px;
					line-height: 1;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					&.owl-prev {
						left: 0;
					}

					&.owl-next {
						right: 0;
					}
				}
			}
		}
	}

	#follow {
		.container {

			display: flex;
			justify-content: space-between;
			text-align: center;
			>.img{
				width: 200px;
				img{
					margin: 0 auto;
				}
			} 
			@media screen and (max-width: 991px){
				>.img{
					display: none;
				}
			}

			> div{
				display: block;
				flex: 1;
			}

			ul {
				list-style: none;
				margin: 0 0 25px 0;
				padding: 0;

				> li {
					display: inline-block;
					font-size: 27px;
					line-height: 1;
					vertical-align: middle;

					+ li {
						margin-left: 15px;
					}

					> a {
						opacity: 1;

						@include hover-focus {
							opacity: 0.8;
						}
					}
				}
			}

			form {
				display: flex;
				flex-direction: row;
				flex-wrap: nowrap;
				margin: 0 auto;
				max-width: 285px;

				> input {
					background: none;
					border: 0;
					border-bottom: 2px solid $color-white;
					color: $color-white;
					flex: 1 1 auto;
					font-size: 12px;
					letter-spacing: letter-spacing(100);
					line-height: 1;
					padding: 0 0 2px 0;
					text-indent: text-indent(100);

					@include placeholder {
						color: $color-white;
						font-weight: 300;
						line-height: 1;
						text-transform: uppercase;
					}

					&:focus {
						border-color: $color-black;
						outline: 0;
					}
				}

				> button {
					background: none;
					border: 2px solid $color-white;
					color: $color-white;
					flex: 0 0 auto;
					font-size: 12px;
					font-weight: 300;
					letter-spacing: letter-spacing(100);
					line-height: 1;
					margin-left: 10px;
					padding: 7px 14px;
					text-indent: text-indent(100);
					text-transform: uppercase;

					@include hover-focus {
						background: $color-white;
						color: $color-black;
					}
				}
			}
		}
	}
}

footer {
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 9px;
	letter-spacing: letter-spacing(200);
	line-height: line-height(14px, 10px);
	padding: 0 0 30px;
	text-align: center;
	text-indent: text-indent(200);

	.container {
		> a {
			display: inline-block;
			margin-bottom: 10px;
			opacity: 1;
			vertical-align: middle;

			@include hover-focus {
				opacity: 0.8;
			}

			> svg {
				height: 17px;
				width: auto;

				> path {
					fill: $color-pink;
				}
			}
		}

		> .copyright {
			> p {
				margin: 0;
			}

			a {
				@include hover-focus {
					text-decoration: underline;
				}
			}
		}
	}
}