$html-font-size: 16px;
$color-white: #FFFDDB;
$color-black: #000;
// $color-pink: #c53754; 
$color-pink: $color-white;
$color-blue-t: #111A00BB;
$color-red-t: $color-blue-t;
$color-blue: #AF0303;

@font-face {
    font-family: 'Gill Sans Nova';
    src: url('../font/GillSansNova-Medium.woff2') format('woff2'),
        url('../font/GillSansNova-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans Nova';
    src: url('../font/GillSansNova-SemiBold.woff2') format('woff2'),
        url('../font/GillSansNova-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gill Sans Nova';
    src: url('../font/GillSansNova-Bold.woff2') format('woff2'),
        url('../font/GillSansNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


$primary-font: 'Gill Sans Nova', sans-serif;
$secondary-font: 'Gill Sans Nova', sans-serif;
$header-font: 'Gill Sans Nova', sans-serif;